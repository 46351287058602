<template>
	<LimitProductForm
		:is-loading="isLoading"
		@onSubmit="handleSubmit"
	/>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import LimitProductForm from '@/components/LimitProductForm.vue';
import { scrollToTop } from '../../assets/js/helpers';
import { ROUTE_NAME } from '../../enums/route';

export default {
	name: 'LimitProductCreate',
	components: {
		LimitProductForm,
	},
	computed: {
		...mapState('limitProduct', {
			create: 'create',
		}),
		isLoading() {
			return this.create.isLoading;
		},
	},
	methods: {
		...mapActions({
			createLimitProduct: 'limitProduct/createLimitProduct',
		}),
		async handleSubmit(payload) {
			try {
				await this.createLimitProduct(payload);
				await this.$router.push({ name: ROUTE_NAME.LIMIT_PRODUCT_PER_ACCOUNT_LIST });
			} catch {
				scrollToTop();
			}
		},
	},
};
</script>
